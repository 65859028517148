import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import format from 'date-fns/format';

import Layout from '../components/Layout';

import './post-page.scss';

export const PostTemplate = ({ post, body /*, authors*/ }) => {
	return (
		<>
			<div className="header--page">
				<section className="header--content">
					<div className="header--page-title">
						<a href="/blog">Blog</a>
					</div>
				</section>
			</div>
			<div className="layouts--section">
				<div className="layouts--container__slim">
					<h1 className="space--mb9 text--jumbo">{post.title}</h1>
					<p className="text--extraSmall text--greyDarker">
						{format(post.date, 'MMMM DD, YYYY  ')}
						<a href={`mailto:${post.author}`}>by {post.author}</a>
					</p>
					{/* eslint-disable-next-line react/no-danger */}
					<div className="blog-post" dangerouslySetInnerHTML={{ __html: body }} />
					<footer className="layouts--blog-footer">TODO: FOOTER</footer>
				</div>
			</div>
		</>
	);
};

// eslint-disable-next-line react/prefer-stateless-function
class BlogPage extends React.Component {
	render() {
		const { data } = this.props;
		const body = data.post.edges[0].node.html;
		const {
			title: seoTitle,
			description: seoDescription,
			browserTitle
		} = data.blog.edges[0].node.frontmatter.seo;
		const { frontmatter: post } = data.post.edges[0].node;

		return (
			<Layout>
				<Helmet>
					<meta name="title" content={seoTitle} />
					<meta name="description" content={seoDescription} />
					<title>{browserTitle}</title>
				</Helmet>
				<PostTemplate post={post} body={body} />
			</Layout>
		);
	}
}

BlogPage.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.array
		})
	})
};

export default BlogPage;

export const pageQuery = graphql`
	query($id: String!) {
		post: allMarkdownRemark(filter: { id: { eq: $id } }) {
			edges {
				node {
					html
					frontmatter {
						title
						date
						summary
						author
					}
				}
			}
		}
		blog: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "blog-page" } } }) {
			edges {
				node {
					frontmatter {
						seo {
							browserTitle
							title
							description
						}
					}
				}
			}
		}
	}
`;
